import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios';

const AdminLogin = () => {
    const [contentLoaded, setContentLoaded] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const passRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        setContentLoaded(true);
    }, [])

    const submitChange = async() => {
        const password = passRef.current.value;

        await axios.post('/adminLogin', {password: password}).then((response) => {
            if(response.data.status !== 'ok') {
                setResponseMessage(response.data.message);
                setTimeout(() => {
                    setResponseMessage('');
                }, 5000)
            } else {
                const token = response.data.accessToken;
                navigate(`/admin?token=${token}`);
            }
        })

        passRef.current.value = '';
    }

    return (
        <div>
            {contentLoaded && (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', gap: 24, width: '100%', backgroundColor: '#232D7D', color: 'white'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8}}>
                        <label>Password</label>
                        <input ref={passRef} type='text' placeholder='Password' />
                        <button onClick={submitChange}>NEXT</button>
                    </div>
                    <span>{responseMessage}</span>
                </div>
            )}
        </div>
    )
}

export default AdminLogin;