import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../../axios';
import { useScreenContext } from '../../Contexts/ScreenContext';
import { useDataContext } from '../../Contexts/DataContext'; 
import { useHeygenAvatar } from '../../Hooks/UseHeygenAvatar';
import { avatarConfig } from '../../Config/avatar.config';
import { VoiceRecorder } from '../Modules/VoiceRecorderComponent';
import { CountdownTimerModule } from '../Modules/CountdownTimerModule';
import { audioDeviceManager } from '../../Services/AudioDeviceManager';
import LoaderModule from '../Modules/LoaderModule';
import { motion, AnimatePresence } from 'framer-motion';
import videoSample from './video-sample.mp4';
//import { events } from '../../Messages';
import './Survey.css';
import { images } from '../../Constants';
import ThinkingModule from '../Modules/ThinkingModule';

const Survey = ({isAvatarActive, isAudioTalkActive}) => {

    const parsedMessageRef = useRef();
    const lastTriggeredFlagRef = useRef(null);
    const questionButtonsRef = useRef(null);
    let requestedCustomType = "";

    const [overrideState, setOverrideState] = useState(false);
    const [stateManager, setStateManager] = useState({
        currentStage: "intro", // current stage of the survey (intro, choiceQuestion, openQuestion, endSurvey)
        requestMode: "item", // mode of the request (item - normal direction of survey, override - additional actions out of standard scope)
        sendInstructionsFlag: false, // Flag for sending intructions after creating realtime session
        sendExpositionFlag: false, // Flag for being in exposition stage
        introduceChoiceQuestionsFlag: false, // When stage changes to choiceQuestion and making an introduction
        readChoiceQuestionFlag: false, // When the first question is about to be asked
        introduceOpenQuestionsFlag: false, // When stage changes to openQuestion and making an introduction
        readOpenQuestionFlag: false, // When the open question is about to be asked
        endSurveyFlag: false, // After all questions when end screen shows up
        userAfterExpositionFlag: false, // Time for user to ask about expo or to go next
        userAfterChoiceQuestionFlag: false, // Time for user to ask about question
        userAfterOpenQuestionFlag: false, // Time for user to response to open question
        userAsksAboutSurveyFlag: false, // When user asks to explain stuff after exposition
        userAsksAboutQuestionFlag: false, // When user asks to explain question
        stopSurveyFlag: false, // When user wants to stop the survey
        finalTimeFlag: false, // When the timer is about to end
    })

    const { surveyData, questionsData, userData, promptData, systemPromptData, externalInfo, savedAIPhrases, setSavedAIPhrases, offlineAppPhrases } = useDataContext();
    const { setCurrentScreen, setError, setEndMessage } = useScreenContext();

    const hasFetchedData = useRef(false);
    const voiceRecorderRef = useRef();
    const countdownTimerRef = useRef();
    const [contentLoaded, setContentLoaded] = useState(false);
    const [finalTimeQueue, setFinalTimeQueue] = useState(false);
    const [audioInitialized, setAudioInitialized] = useState(false);
    const [isAvatarTalking, setIsAvatarTalking] = useState(false);
    const [showQuestionUI, setShowQuestionUI] = useState(false);
    const [showQuestionOptions, setShowQuestionOptions] = useState(true);
    const [userMessage, setUserMessage] = useState("");
    const [userInputActive, setUserInputActive] = useState(false);
    const [radioButtonActive, setRadioButtonActive] = useState(false);
    const [isThinking, setIsThinking] = useState(true);
    const [currentQuestionId, setCurrentQuestionId] = useState(0);
    const [selectedRadioOption, setSelectedRadioOption] = useState(0);
    const [savedQuestionResponses, setSavedQuestionResponses] = useState([]);
    const [chatOutput, setChatOutput] = useState([]);
    const [conversationContext, setConversationContext] = useState([
        { 
            state: "instructions",
            message: {
                "role": "developer", 
                "content": [{ 
                    "type": "text", 
                    "text": replacePlaceholders(systemPromptData.prompt, {surveyData, externalInfo})
                }]
            }
        },
    ]);

    const {
        avatarVideoRef,
        initializeAvatarSession,
        avatarSpeak,
        terminateAvatarSession,
        isAvatarSpeaking,
        isAvatarInitialized,
        registerCallbacks, 
        avatarError
      } = useHeygenAvatar(avatarConfig);

    const scaleTypes = {
        important: ["WAŻNE", "RACZEJ WAŻNE", "NIE MAM ZDANIA", "RACZEJ NIEWAŻNE", "NIEWAŻNE"],
        yesno: ["TAK", "NIE", "NIE MAM ZDANIA"],
        yesnoexpanded: ["TAK", "RACZEJ TAK", "NIE MAM ZDANIA", "RACZEJ NIE", "NIE"],
    }

    const createEvent = (promptIndex, params = {}) => ({
        role: "user",
        content: [{
            type: "text",
            text: replacePlaceholders(promptData[promptIndex].prompt, params)
        }]
    })

    const events = {
        tell_about_survey: () => createEvent(0),
        user_after_exposition: (userInput) => createEvent(1, {userInput}),
        user_asks_about_survey: (userInput) => createEvent(2, {userInput}),
        introduce_questions: (choiceNum, openNum) => createEvent(3, {choiceNum, openNum}),
        read_question: (question, hint) => createEvent(4, {question, hint}),
        user_after_question: (userInput) => createEvent(5, {userInput}),
        user_asks_about_question: (userInput) => createEvent(6, {userInput}),
        introduce_open_questions: () => createEvent(7),
        read_open_question: (question, hint) => createEvent(8, {question, hint}),
        user_after_open_question: (userInput) => createEvent(9, {userInput}),
        end_of_survey: () => createEvent(10),
        stop_survey: (userInput) => createEvent(11, {userInput}),
    }

    // AT FIRST RENDER
    useEffect(() => {
        if(!hasFetchedData.current) {
            try {
                hasFetchedData.current = true;

                const updateState = () => {
                    setContentLoaded(true);
                    setStateManager((prev) => ({...prev, sendExpositionFlag: true}));
                    countdownTimerRef.current.startTimer();
                }
    
                let avatarInitPromise = Promise.resolve(true);
                let audioInitPromise = Promise.resolve(true);
                let tokenBalancePromise = Promise.resolve(true);
                let microphoneCheckPromise = Promise.resolve(true);
    
                if(isAvatarActive) {
                    avatarInitPromise = initializeAvatarSession().then((res) => {
                        if (!res.isInit) {
                            console.log(res?.error);
                            setError(false, "Initializing avatar session failed.")
                            return false;
                        }
                        return true; 
                    });
                } 

                if(isAvatarActive) {
                    tokenBalancePromise = axios.get('/getTokenBalance').then((res) => {
                        if(res.data.tokenBalance <= res.data.tokenBalanceTreshold) {
                            setError(false, "Not enough Heygen tokens.")
                            return false;
                        }
                        if(res.data.status === 'bad') {
                            setError(false, "Server error while getting token balance.")
                            return false;
                        }
                        if(res.data.status === 'offline') {
                            setError(true, "Przepraszamy. Aplikacja jest obecnie wyłączona.")
                        }
                        return true;
                    })
                }

                if (isAudioTalkActive) {
                    microphoneCheckPromise = navigator.permissions.query({name: 'microphone'}).then(async(result) => {
                        if(result.state === "granted") {
                            return true;
            
                        } else if(result.state === "denied") {
                            setError(true, "Przeglądarka zablokowała uprawnienia do mikrofonu. Niestety są one niezbędne do działania aplikacji. Wejdź w ustawienia przeglądarki -> Prywatność i Bezpieczeństwo, a następnie przyznaj uprawnienia do mikrofonu dla tego adresu.");
                            return false;
                        
                        } else {
                            await navigator.mediaDevices.getUserMedia({ audio: true });
                            await audioDeviceManager.updateAvailableDevices();
                            return true;
                        }
                    });
                }
    
                if (isAudioTalkActive) {
                    audioInitPromise = voiceRecorderRef.current.initVoiceComponent().then(() => {
                        return true;
                    })
                }
    
                Promise.all([avatarInitPromise, tokenBalancePromise, microphoneCheckPromise, audioInitPromise]).then(() => {
                    updateState();
                });

            } catch(err) {
                console.log(err);
                setError(false, "Error while initializing the survey.")
            }
        }

    }, []);

    useEffect(() => {
        const beforeUnloadListener = (event) => {
            const confirmationMessage = "Na pewno chcesz opuścić stronę? Spowoduje to zakończenie ankiety."
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }

        const handleUnload = (event) => {
            try {
                handleEndSurvey();
            } catch(err) {}
        }

        window.addEventListener("beforeunload", beforeUnloadListener);
        window.addEventListener("unload", handleUnload);

        return () => {
            window.removeEventListener("beforeunload", beforeUnloadListener);
            window.removeEventListener("unload", handleUnload);
        }

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if(!isAvatarTalking && finalTimeQueue) {
                handleTimerComplete();
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [finalTimeQueue, isAvatarTalking]);

    // TERMINATE AVATAR
    useEffect(() => {
        const handleBeforeUnload = () => {
            terminateAvatarSession();
        }

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            terminateAvatarSession();
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
    }, [terminateAvatarSession])

    const handleStartTalking = useCallback(() => {
        setIsAvatarTalking(true);
    }, []);
    
    const handleStopTalking = useCallback(() => {
        setIsAvatarTalking(false);
        console.log("Stopped talking");

        if(shouldContinue) {
            handleAfterTalking();
        }

        shouldContinue = true;
    }, []);

    // Register Callbacks from avatar hook
    useEffect(() => {
        registerCallbacks({
          onStart: handleStartTalking,
          onStop: handleStopTalking
        });
    }, [handleStartTalking, handleStopTalking, registerCallbacks]);

    function replacePlaceholders(template, data) {
        return template.replace(/\${(.*?)}/g, (_, key) => {
            const keys = key.trim().split('.');
            let value = data;
            for (const k of keys) {
                value = value?.[k];
                if (value === undefined) break;
            }
            return value !== undefined ? value : `\${${key}}`;
        });
    }

    const sendEvent = async(eventGenerator, saveInContext, fullContext, ...args) => {
        const event = eventGenerator(...args);
        
        setIsThinking(true);

        axios.post('/sendAIMessage', {context: fullContext ? conversationContext : [conversationContext[0]], event: event}).then((response) => {

            if(response.data.status === 'offline') {
                setError(true, offlineAppPhrases[Math.floor(Math.random() * offlineAppPhrases.length)]);
                return;
            }

            if(saveInContext) {
                setConversationContext((prev) => [...prev, {state: "user", message: {"role": event.role, "content": [{"type": "text", "text": event.content[0].text}]}}]);
            }
                
            setIsThinking(false);
            handleResponseDone(response.data.response);
            console.log(response.data.response);
            console.log(...conversationContext, {state: "user", message: {"role": event.role, "content": [{"type": "text", "text": event.content[0].text}]}});
        });
    }

    const handlers = {
        sendExposition: () => {
            sendEvent(events.tell_about_survey, true, true);
        },

        sendUserResponse: (audioInput) => {
            if(audioInput?.length > 0) {
                if(!stateManager.userAsksAboutSurveyFlag && !stateManager.userAsksAboutQuestionFlag) {
                    setChatOutput((prev) => [{id: chatOutput.length, who: 'USER', text: audioInput}, ...prev]);
                    setUserMessage(audioInput);
                    stopUserUIResponse();
                    setShowQuestionOptions(false);
    
                    let generator = null;
                    
                    if(overrideState) {
                        if(stateManager.stopSurveyFlag) generator = events.stop_survey;
                    } else {
                        if(stateManager.userAfterExpositionFlag) generator = events.user_after_exposition;
                        if(stateManager.userAfterChoiceQuestionFlag) generator = events.user_after_question;
                        if(stateManager.userAfterOpenQuestionFlag) generator = events.user_after_open_question;
                    }
    
                    sendEvent(generator, false, true, audioInput);
                } 
            } else if(userMessage.length > 0 && !stateManager.userAsksAboutSurveyFlag && !stateManager.userAsksAboutQuestionFlag) {
                setChatOutput((prev) => [{id: chatOutput.length, who: 'USER', text: userMessage}, ...prev]);
                stopUserUIResponse();
                setShowQuestionOptions(false);
    
                let generator = null;
    
                if(overrideState) {
                    if(stateManager.stopSurveyFlag) generator = events.stop_survey;
                } else {
                    if(stateManager.userAfterExpositionFlag) generator = events.user_after_exposition;
                    if(stateManager.userAfterChoiceQuestionFlag) generator = events.user_after_question;
                    if(stateManager.userAfterOpenQuestionFlag) generator = events.user_after_open_question;
                }
    
                sendEvent(generator, false, true, userMessage);
            } else {
                //allowUserToRespond();
            }
        },

        userAsksAboutSurvey: () => {
            sendEvent(events.user_asks_about_survey, true, true, userMessage);
        },

        introduceQuestions: () => {
            sendEvent(events.introduce_questions, true, false, questionsData.choice.length, questionsData.open.length);
        },

        readQuestion: () => {
            const question = questionsData.choice[currentQuestionId]?.question;
            const hint = questionsData.choice[currentQuestionId]?.hint;
            sendEvent(events.read_question, true, false, question, hint);
        },

        introduceOpenQuestions: () => {
            sendEvent(events.introduce_open_questions, true, false);
        },

        readOpenQuestion: () => {
            const question = questionsData.open[currentQuestionId]?.question;
            const hint = questionsData.open[currentQuestionId]?.hint;
            sendEvent(events.read_open_question, true, false, question, hint);
        },

        userAsksAboutQuestion: () => {
            sendEvent(events.user_asks_about_question, true, true, userMessage);
        },

        endSurvey: () => {
            sendEvent(events.end_of_survey, true, false);
        },

        finalTimeNotice: async() => {
            stopUserUIResponse();
            const message = "Niestety musimy kończyć.";
            setChatOutput((prev) => [
                {id: chatOutput.length, who: 'AI', text: message}, ...prev
            ]);
            await handleAvatarSpeak(message);
            setTimeout(() => {
                setEndMessage("Czas minął. Co prawda, nie skończyłeś/-aś ankiety, ale wszystkie Twoje odpowiedzi zostały zapisane!")
                handleEndSurvey();
            }, 2500);
        },
    }

    useEffect(() => {
        let actionMap = {}

        if(!overrideState) {
            actionMap = {
                sendInstructionsFlag: handlers.sendIntstructions,
                sendExpositionFlag: handlers.sendExposition,
                introduceChoiceQuestionsFlag: handlers.introduceQuestions,
                readChoiceQuestionFlag: handlers.readQuestion,
                introduceOpenQuestionsFlag: handlers.introduceOpenQuestions,
                readOpenQuestionFlag: handlers.readOpenQuestion,
                endSurveyFlag: handlers.endSurvey,
                userAfterExpositionFlag: handlers.sendUserResponse,
                userAfterChoiceQuestionFlag: handlers.sendUserResponse,
                userAfterOpenQuestionFlag: handlers.sendUserResponse,
                userAsksAboutSurveyFlag: handlers.userAsksAboutSurvey,
                userAsksAboutQuestionFlag: handlers.userAsksAboutQuestion,
            };  
        } else {
            actionMap = {
                finalTimeFlag: handlers.finalTimeNotice,
            }
        }
    
        Object.entries(actionMap).forEach(([flag, handler]) => {
            if(stateManager[flag]) {
                handler();
            }
        })
    }, [stateManager])

    const handleQuestion = () => {
        stopUserUIResponse();

        if(stateManager.currentStage === 'choiceQuestion') {
            setSelectedRadioOption(0);

            if(currentQuestionId < questionsData.choice.length - 1) {
                setCurrentQuestionId(currentQuestionId + 1);
                setStateManager((prev) => ({...prev, readChoiceQuestionFlag: true, userAfterChoiceQuestionFlag: false}));

            } else {
                setCurrentQuestionId(0);
                setStateManager((prev) => ({...prev, requestMode: 'item', currentStage: "openQuestion", readOpenQuestionFlag: true, userAfterChoiceQuestionFlag: false}));
            }
        } else if(stateManager.currentStage === 'openQuestion') {
            if(currentQuestionId < questionsData.open.length - 1) {
                setCurrentQuestionId(currentQuestionId + 1);
                setStateManager((prev) => ({...prev, requestMode: 'item', readOpenQuestionFlag: true, userAfterOpenQuestionFlag: false}));
            } else {
                setCurrentQuestionId(0);
                setStateManager((prev) => ({...prev, requestMode: 'item', currentStage: "endSurvey", endSurveyFlag: true, userAfterOpenQuestionFlag: false}));
            }
        }
    }

    const handleRadioInput = (event) => {
        try {
            voiceRecorderRef.current.deactivateRecording();
        } catch(err) {
            console.log("Voice recorder not active.");
        }
        setRadioButtonActive(false);
        setShowQuestionUI(false);
        setSelectedRadioOption(event);
        setChatOutput((prev) => [{id: chatOutput.length, who: 'USER', text: event}, ...prev])
        setSavedQuestionResponses((prev) => [...prev, {questionId: questionsData.choice[currentQuestionId].id, userId: userData.userId, response: event}]);
        
        handleQuestion();
    }

    const handleSkipQuestion = () => {
        setChatOutput((prev) => [{id: chatOutput.length, who: 'USER', text: "NOT RESPONDED"}, ...prev])
        setSavedQuestionResponses((prev) => [...prev, {questionId: questionsData.choice[currentQuestionId].id, userId: userData.userId, response: "NOT RESPONDED"}]);
        handleQuestion();
    }

    const handleBackToPreviousQuestion = () => {
        if(stateManager.currentStage === 'choiceQuestion') {
            setCurrentQuestionId(currentQuestionId - 1);
            setStateManager((prev) => ({...prev, readChoiceQuestionFlag: true, userAfterChoiceQuestionFlag: false}));
        } else if(stateManager.currentStage === 'openQuestion') {
            setCurrentQuestionId(currentQuestionId - 1);
            setStateManager((prev) => ({...prev, requestMode: 'item', readOpenQuestionFlag: true, userAfterOpenQuestionFlag: false}));
        }
    }

    const handleAvatarSpeak = (message) => {
        if(isAvatarActive) {
            avatarSpeak(message);
        } else {
            if(shouldContinue) {
                handleAfterTalking();
            } else {
                setChatOutput((prev) => [
                    {id: chatOutput.length, who: 'AI', text: message}, ...prev
                ]);
            }
            shouldContinue = true;
        }
    }

    const handleResponseDone = (parsedMessage) => {
        parsedMessageRef.current = parsedMessage;
        console.log(parsedMessage);

        setChatOutput((prev) => [
            {id: chatOutput.length, who: 'AI', text: parsedMessage.content}, ...prev
        ]);

        let responseHandlers = {};

        if(!overrideState) {
            responseHandlers = {
                sendExpositionFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                userAsksAboutSurveyFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                introduceChoiceQuestionsFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                readChoiceQuestionFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                userAsksAboutQuestionFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                introduceOpenQuestionsFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                readOpenQuestionFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
                endSurveyFlag: () => handleAvatarSpeak(parsedMessageRef.current.content),
    
                userAfterExpositionFlag: (msg) => {
                    console.log(lastTriggeredFlagRef.current);
                    if (!stateManager.userAsksAboutSurveyFlag) {
                        responseProcessUserAfterExposition(convertToJSON(msg.content))
                    }
                },
    
                userAfterChoiceQuestionFlag: (msg) => {
                    if (!stateManager.userAsksAboutQuestionFlag) {
                        responseProcessUserAfterChoiceQuestion(convertToJSON(msg.content))
                    }
                },
    
                userAfterOpenQuestionFlag: (msg) => {
                    if (!stateManager.userAsksAboutQuestionFlag) {
                        responseProcessUserAfterOpenQuestion(convertToJSON(msg.content))
                    }
                },
            }
        } else {
            responseHandlers = {
                stopSurveyFlag: (msg) => {
                    responseProcessUserAfterCancelSurvey(convertToJSON(msg.content))
                }
            }
        }
        

        for (const [flag, handler] of Object.entries(responseHandlers)) {
            if (stateManager[flag]) {
                lastTriggeredFlagRef.current = flag;
                handler(parsedMessage);

                break;
            }
        }
    }

    const handleAfterTalking = () => {   
        setShowQuestionOptions(true);

        let responseHandlers = {};
        
        if(!overrideState) {
            responseHandlers = {
                sendExpositionFlag: responseTransitionAfterExposition,
                userAsksAboutSurveyFlag: responseUserAskedToExplainSurvey,
                introduceChoiceQuestionsFlag: responseIntroduceQuestions,
                readChoiceQuestionFlag: responseReadChoiceQuestion,
                userAsksAboutQuestionFlag: responseUserAskedToExplainQuestion,
                introduceOpenQuestionsFlag: responseIntroduceOpenQuestions,
                readOpenQuestionFlag: responseReadOpenQuestion,
                endSurveyFlag: responseFinalizeSurvey,
    
                userAfterExpositionFlag: () => {
                    if (!stateManager.userAsksAboutSurveyFlag) {
                        switch(requestedCustomType) {
                            case 'cancel': {
                                requestedCustomType = '';
                                setUserMessage("");
                                setOverrideState(true);
                                setStateManager((prev) => ({
                                    ...prev, 
                                    stopSurveyFlag: true,
                                }));
    
                                allowUserToRespond(true);
                                
                                break;
                            }
                            default: {
                                setStateManager((prev) => ({
                                    ...prev, 
                                    requestMode: "item"
                                }));
                            
                                allowUserToRespond();
                            }
                        }
                    }
                },
    
                userAfterChoiceQuestionFlag: () => {
                    if (!stateManager.userAsksAboutQuestionFlag) {
                        switch(requestedCustomType) {
                            case 'skip': {
                                requestedCustomType = '';
                                handleSkipQuestion();
                                break;
                            }
                            case 'oneMoreTime': {
                                requestedCustomType = '';
                                setUserMessage("");
                                handleBackToPreviousQuestion();
                                break;
                            }
                            case 'cancel': {
                                requestedCustomType = '';
                                setUserMessage("");
                                setOverrideState(true);
                                setStateManager((prev) => ({
                                    ...prev, 
                                    stopSurveyFlag: true,
                                }));
    
                                allowUserToRespond(true);
                                
                                break;
                            }
                            default: {
                                setStateManager((prev) => ({
                                    ...prev, 
                                    requestMode: "item"
                                }));
                            
                                allowUserToRespond();
                            }
                        }
    
                        
                    }
                },
    
                userAfterOpenQuestionFlag: () => {
                    if (!stateManager.userAsksAboutQuestionFlag) {
                        switch(requestedCustomType) {
                            case 'skip': {
                                requestedCustomType = '';
                                setSavedQuestionResponses((prev) => [...prev, {questionId: questionsData.open[currentQuestionId].id, userId: userData.userId, response: "NOT RESPONDED"}]);
                                setUserMessage("");
                                handleQuestion();
                                break;
                            }
                            case 'oneMoreTime': {
                                requestedCustomType = '';
                                setUserMessage("");
                                handleBackToPreviousQuestion();
                                break;
                            }
                            case 'cancel': {
                                requestedCustomType = '';
                                setUserMessage("");
                                setOverrideState(true);
                                setStateManager((prev) => ({
                                    ...prev, 
                                    stopSurveyFlag: true,
                                }));
    
                                allowUserToRespond(true);
                                
                                break;
                            }
                            default: {
                                setStateManager((prev) => ({
                                    ...prev, 
                                    requestMode: "item"
                                }));
                            
                                allowUserToRespond();
                            }
                        }
                    }
                },
            }
        } else {
            responseHandlers = {
                stopSurveyFlag: () => {
                    switch(requestedCustomType) {
                        case "cancelyes": {
                            stopUserUIResponse();
                            handleEndSurvey();
                            break;
                        }
                        case "cancelno": {
                            setUserMessage("");
                            allowUserToRespond();
                            setOverrideState(false);
                            setStateManager((prev) => ({
                                ...prev,
                                requestMode: "item",
                                stopSurveyFlag: false,
                            }));
                            break;
                        }
                        default: {
                            console.log("OpenAI fucked up somewhere");
                        }
                    }
                },
            }
        }           

        if(lastTriggeredFlagRef.current) {
            console.log(lastTriggeredFlagRef.current);
            console.log(overrideState);
            const handler = responseHandlers[lastTriggeredFlagRef.current];
            if(handler) {
                handler(parsedMessageRef.current);
            }
        }
    }

    const responseTransitionAfterExposition = (parsedMessage) => {
        setConversationContext((prev) => [...prev, {state: "exposition", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setShowQuestionUI(true);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            sendExpositionFlag: false, 
            userAfterExpositionFlag: true
        }));

        allowUserToRespond();
    }

    const responseProcessUserAfterExposition = (responseType) => {
        handleResponseType(responseType.type, {
            done: () => {
                setUserMessage("");
                setStateManager((prev) => ({
                    ...prev, 
                    requestMode: "item", 
                    userAfterExpositionFlag: false, 
                    introduceChoiceQuestionsFlag: true
                }));
            },
            explain: () => {
                setStateManager((prev) => ({
                    ...prev, 
                    requestMode: "item", 
                    userAsksAboutSurveyFlag: true
                }));
            },
            cancel: () => {
                requestedCustomType = "cancel";
                matchFixedResponse("cancel", savedAIPhrases);
            },
        }, savedAIPhrases);
    }

    const responseUserAskedToExplainSurvey = (parsedMessage) => {
        setConversationContext((prev) => [...prev, {state: "exposition", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev,
            requestMode: "item",
            userAsksAboutSurveyFlag: false
        }));

        allowUserToRespond();
    }

    const responseIntroduceQuestions = (parsedMessage) => {
        removeContext(["exposition"]);
        setConversationContext((prev) => [...prev, {state: "choiceQuestions", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            introduceChoiceQuestionsFlag: false, 
            currentStage: 'choiceQuestion', 
            readChoiceQuestionFlag: true
        }));
    }

    const responseReadChoiceQuestion = (parsedMessage) => {
        removeContext(["choiceQuestions"]);
        console.log("Read question after talk");
        setShowQuestionUI(true);
        setConversationContext((prev) => [...prev, {state: "choiceQuestions", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            readChoiceQuestionFlag: false, 
            userAfterChoiceQuestionFlag: true
        }));

        allowUserToRespond();
    }

    const responseProcessUserAfterChoiceQuestion = (responseType) => {
        handleResponseType(responseType.type, {
            explain: () => {
                setStateManager((prev) => ({
                    ...prev, 
                    requestMode: "item", 
                    userAsksAboutQuestionFlag: true
                }));
                stopUserUIResponse();
            },
            skip: () => {
                requestedCustomType = "skip";
                matchFixedResponse("skip", savedAIPhrases);
            },
            oneMoreTime: () => {
                if(currentQuestionId == 0) {
                    matchFixedResponse("oneMoreTimeFail", savedAIPhrases);
                } else {
                    requestedCustomType = "oneMoreTime";
                    matchFixedResponse("oneMoreTimeSuccess", savedAIPhrases);
                }
            },
            cancel: () => {
                requestedCustomType = "cancel";
                matchFixedResponse("cancel", savedAIPhrases);
            },
        }, savedAIPhrases);
    }

    const responseUserAskedToExplainQuestion = (parsedMessage) => {
        setShowQuestionUI(true);
        setConversationContext((prev) => [...prev, {state: "explainQuestion", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            userAsksAboutQuestionFlag: false
        }));

        allowUserToRespond();
    }

    const responseIntroduceOpenQuestions = (parsedMessage) => {
        removeContext(["choiceQuestions", "explainQuestion"]);
        setConversationContext((prev) => [...prev, {state: "openQuestions", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            introduceOpenQuestionsFlag: false, 
            readOpenQuestionFlag: true
        }));
    }

    const responseReadOpenQuestion = (parsedMessage) => {
        setShowQuestionUI(true);
        setConversationContext((prev) => [...prev, {state: "openQuestions", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            readOpenQuestionFlag: false, 
            userAfterOpenQuestionFlag: true
        }));

        allowUserToRespond();
    }

    const responseProcessUserAfterOpenQuestion = (responseType) => {
        handleResponseType(responseType.type, {
            done: () => {
                setSavedQuestionResponses((prev) => [...prev, {questionId: questionsData.open[currentQuestionId].id, userId: userData.userId, response: userMessage}]);
                setUserMessage("");
                handleQuestion();
            },
            explain: () => {
                setStateManager((prev) => ({
                    ...prev, 
                    requestMode: "item", 
                    userAsksAboutQuestionFlag: true
                }));
                stopUserUIResponse();
            },
            skip: () => {
                requestedCustomType = "skip";
                matchFixedResponse("skip", savedAIPhrases);
            },
            oneMoreTime: () => {
                if(currentQuestionId == 0) {
                    matchFixedResponse("oneMoreTimeFail", savedAIPhrases);
                } else {
                    requestedCustomType = "oneMoreTime";
                    matchFixedResponse("oneMoreTimeSuccess", savedAIPhrases);
                }
            },
            cancel: () => {
                requestedCustomType = "cancel";
                matchFixedResponse("cancel", savedAIPhrases);
            },
        }, savedAIPhrases);
    }

    const responseFinalizeSurvey = (parsedMessage) => {
        removeContext(["openQuestions", "explainQuestion"]);
        setConversationContext((prev) => [...prev, {state: "end", message: {"role": "assistant", "content": [{"type": "text", "text": parsedMessage.content}]}}]);
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            endSurveyFlag: false
        }));
        
        handleEndSurvey();
    }

    const responseProcessUserAfterCancelSurvey = (responseType) => {
        handleResponseType(responseType.type, {
            cancelyes: () => {
                requestedCustomType = "cancelyes";
                matchFixedResponse("cancelyes", savedAIPhrases);
            },
            cancelno: () => {
                requestedCustomType = "cancelno";
                matchFixedResponse("cancelno", savedAIPhrases);
            },
        }, savedAIPhrases);
    }

    const handleResponseType = (type, actions) => {
        if (actions[type]) {
            actions[type]();

        } else {
            matchFixedResponse(type, savedAIPhrases);
        }
    }

    const matchFixedResponse = (type, defaultResponses = []) => {
        const matchingResponses = defaultResponses.filter(resp => resp.type === type);
            
        if (matchingResponses.length > 0) {
            const randomResponse = matchingResponses[Math.floor(Math.random() * matchingResponses.length)];

            
            handleAvatarSpeak(randomResponse.phrase)

            setChatOutput((prev) => [
                {id: chatOutput.length, who: 'AI', text: randomResponse.phrase}, ...prev
            ])

        } else {
            handleAvatarSpeak("Brak frazy")

            setChatOutput((prev) => [
                {id: chatOutput.length, who: 'AI', text: "BRAK FRAZY"}, ...prev
            ])
        }
    }

    const handleEndSurvey = async() => {
        await axios.post('/submitSurvey', {
            surveyId: surveyData.id, 
            userId: userData.userId, 
            answers: savedQuestionResponses, 
            report: chatOutput
        }).then((response) => {
            if(response.data.status === 'offline') {
                setError(true, offlineAppPhrases[Math.floor(Math.random() * offlineAppPhrases.length)]);
                return;
            }
            console.log("Data sent to database: ", response.data);
        });

        terminateAvatarSession();
        setCurrentScreen("end");
    }

    const removeContext = (states) => {  
        setConversationContext((prev) => {
            const userElements = prev.filter((item) => item.state === "user");
            const otherElements = prev.filter((item) => item.state !== "user");
    
            const lastElementsByState = states.map(state => {
                const elementsWithState = prev.filter(item => item.state === state);
                return elementsWithState[elementsWithState.length - 1];
            }).filter(Boolean);
    
            const filteredElements = otherElements.filter((item) => !states.includes(item.state));
    
            return filteredElements.concat(lastElementsByState, userElements.slice(-4));
        });
    }

    const convertToJSON = (codeBlock) => {
        try {
            const trimmedInput = codeBlock.replace(/^```|```$/g, '').replace(/[\n\/]/g, '').replace(/\n/g, '').trim();

            const regex = /^{\s*"?type"?\s*:\s*"?([^"}]+)"?\s*}$/i;
            const match = trimmedInput.match(regex);
            return { type: match[1] };
            
        } catch (error) {
            console.error("Failed to parse code block to JSON:", error);
            return null;
        }
    };

    const allowUserToRespond = async(overrideStateArg) => {
        if(isAudioTalkActive) await voiceRecorderRef.current.activateRecording();
        setUserInputActive(true);
        setUserMessage("");

        if(overrideStateArg) { 
            setRadioButtonActive(false); 
        }  else { 
            console.log(overrideStateArg, "2");
            setRadioButtonActive(true); 
        }
    }

    const stopUserUIResponse = async() => {
        if(isAudioTalkActive) await voiceRecorderRef.current.deactivateRecording();
        setUserInputActive(false);
        setRadioButtonActive(false);
    }

    const onPressEnter = (e) => {
        if(e.key === "Enter") {
            handlers.sendUserResponse();
        }
    }

    let shouldContinue = true;
    const avatarTalkWithoutFurtherActions = (message) => {
        shouldContinue = false;
        handleAvatarSpeak(message);
    }

    const handleTimerHalfway = () => {

    }

    const handleTimerComplete = () => {
        setFinalTimeQueue(false);
        shouldContinue = false;
        stopUserUIResponse();
        handleStopTalking();
        setOverrideState(true);
        setStateManager((prev) => ({...prev, finalTimeFlag: true}));
        lastTriggeredFlagRef.current = "finalTimeFlag";
    }

    const endIntroByButton = () => {
        setRadioButtonActive(false);
        stopUserUIResponse();
        setShowQuestionOptions(false);
        
        setUserMessage("");
        setStateManager((prev) => ({
            ...prev, 
            requestMode: "item", 
            userAfterExpositionFlag: false, 
            introduceChoiceQuestionsFlag: true
        }));
    }

    return (
        <div className='survey__container'>
            <div className='survey'>
                <div className='survey__bar'>
                    <div className='survey__logo'>
                        <img src={images.logoWide}/>
                    </div>
                    <CountdownTimerModule 
                     ref={countdownTimerRef} 
                     onWarning={() => handleTimerHalfway()}
                     onComplete={() => setFinalTimeQueue(true)} 
                     initialDelay={420} 
                     questionNumberLeft={questionsData.choice.length + questionsData.open.length - savedQuestionResponses.length} 
                    />
                    {isAudioTalkActive && (
                        <VoiceRecorder 
                         ref={voiceRecorderRef} 
                         isParentInitialized={contentLoaded} 
                         setInitialized={(isInit) => setAudioInitialized(isInit)} 
                         transcriptionComplete={(text) => {handlers.sendUserResponse(text)}} 
                         firstWarningDueToInactivity={(text) => {avatarTalkWithoutFurtherActions(text)}} 
                         stopSurveyDueToInactivity={() => {handleEndSurvey()}} />
                    )}
                </div>                 
                {isAvatarActive && (
                    <div className='avatar-interface'>
                        <video ref={avatarVideoRef} playsInline autoPlay className='avatar-video' />
                    </div>
                )}
                {!isAvatarActive && (
                    <div className='avatar-interface'>
                        <video ref={avatarVideoRef} className='avatart-video' controls={true}>
                            <source src={videoSample} type="video/mp4/"/>
                        </video>
                    </div>
                )}
                {stateManager.currentStage === "intro" && (
                    <AnimatePresence>
                        {showQuestionUI && (
                            <motion.div className='survey__question-box'
                                initial={{ opacity: 0, y: 100 }}
                                animate={{ opacity: 1, y: showQuestionOptions ? 0 : questionButtonsRef.current.offsetHeight, transition: { duration: 0.4 } }}
                                exit={{ opacity: 0, y: 100, transition: { duration: 0.2 } }}
                            >
                                <div ref={questionButtonsRef} className='survey__question-buttons solo'>
                                    <button 
                                        className={`survey__question-button`} 
                                        disabled={!radioButtonActive} 
                                        onClick={endIntroByButton}>
                                        ZACZYNAMY!
                                    </button>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                )}
                {stateManager.currentStage === "choiceQuestion" && (
                    <AnimatePresence>
                        {showQuestionUI && (
                            <motion.div className='survey__question-box'
                                initial={{ opacity: 0, y: 100 }}
                                animate={{ opacity: 1, y: showQuestionOptions ? 0 : questionButtonsRef.current.offsetHeight, transition: { duration: 0.4 } }}
                                exit={{ opacity: 0, y: 100, transition: { duration: 0.2 } }}
                            >
                                <div className='survey__question-background'>
                                    <div className='survey__question'>
                                        <h3>Pytanie {currentQuestionId + 1} / {questionsData.choice.length}</h3>
                                        <p>{questionsData.choice[currentQuestionId].question}</p>
                                    </div>
                                </div>
                                <div ref={questionButtonsRef} className='survey__question-buttons'>
                                    {  
                                        scaleTypes[questionsData.choice[currentQuestionId].scale].map((item, index) => (
                                            <button 
                                                className={`survey__question-button ${selectedRadioOption === item ? "selected" : ""}`} 
                                                key={index} 
                                                disabled={!radioButtonActive} 
                                                onClick={() => { handleRadioInput(item)}}>
                                                {item}
                                            </button>
                                        ))
                                    }
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    
                )}
                {stateManager.currentStage === "openQuestion" && (
                    <AnimatePresence>
                        {showQuestionUI && (
                            <motion.div className='survey__question-box'
                                initial={{ opacity: 0, y: 100 }}
                                animate={{ opacity: 1, y: 0, transition: { duration: 0.4 } }}
                                exit={{ opacity: 0, y: 100, transition: { duration: 0.4 } }}
                            >
                                <div className='survey__question-background'>
                                    <div className='survey__question'>
                                        {/* <h3>Pytanie {currentQuestionId + 1} / {questionsData.open.length}</h3> */}
                                        <h3>Pytanie otwarte</h3>
                                        <p>{questionsData.open[currentQuestionId].question}</p>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                )}
            </div>
            <div>
                <div className='survey__user-area'>
                        <textarea value={userMessage} disabled={!userInputActive} onChange={(e) => setUserMessage(e.target.value)} onKeyDown={(e) => onPressEnter(e)}></textarea>
                        <button disabled={!userInputActive} onClick={handlers.sendUserResponse}>SEND</button>
                </div>
                <ul>
                    <h2><i><b>CHAT LOG</b></i></h2>
                    {chatOutput.map((item, index) => (
                        <li key={index} className={`${item.who === "USER" ? 'user' : 'ai'}`}><b>{item.who}</b>: {item.text}</li>
                    ))}
                </ul>
            </div>

            <AnimatePresence>
                {isThinking && (
                    <motion.div className='thinking_box'
                        initial={{opacity: 0, transition: {duration: 0.5}}}
                        animate={{opacity: 1, transition: {duration: 0.5}}}
                        exit={{opacity: 0, transition: {duration: 0.5}}}
                    >
                        <ThinkingModule />
                     </motion.div>
                )}
            </AnimatePresence>
               
            <AnimatePresence>
                {!contentLoaded && (
                    <motion.div 
                        style={{width: '100vw', height: '100dvh', position: 'fixed', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: '#232D7D'}}
                        exit={{ opacity: 0, transition: { duration: 0.4 } }}
                    >
                        <LoaderModule type={'avatar'} />
                    </motion.div>
                )}
            </AnimatePresence>      
            
        </div>
    )
};

export default Survey;