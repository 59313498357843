import { AnimatePresence, motion, transform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import { images } from '../../Constants';

const  ThinkingModule = () => {

    const cssStyling = {
        loader: {
            display: 'flex',
            gap: 16,
            alignItems: 'center',
            color: 'black',
            backgroundColor: 'white',
            padding: '8px 24px',
            borderRadius: '16px',
            boxShadow: '0 0 8px black'
        },

        paragraph: {
            
        }
    }

    return (
        <div style={cssStyling.loader}>
            <BeatLoader height={4} width={16} color='black' speedMultiplier={0.75} />
            <p style={cssStyling.paragraph}>Myślę...</p>
        </div>
        
    )
}
    
export default ThinkingModule;